<template>
  <div class="detail_box">
    <div class="wrapper" v-if="list && list.length > 0">
        <div style="padding-bottom: 200px;">
          <div
            class="flex_center just_sp font_size14_f_5 div"
            v-for="(items, index) in list"
            :key="index"
          >
            <div class="flex_div">
              <div style="width: 90%">
                <div class="span1">{{ items.get_recommend_member.mobile }}</div>
                <div class="div_flex">
                  <div class="hui">注册时间:{{ items.created_at }}</div>
                </div>
              </div>
              <div class="red_div">+{{ items.money }}</div>
            </div>
          </div>
        </div>
    </div>
    <div v-else>
      <null class="null"></null>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list", "isMore", "loading"],
  data() {
    return {};
  },
  methods: {},
  components: {
    null: () => import("@/common/null"),
  },
};
</script>
<style lang="less">
.detail_box {
  margin-top: 1.17rem;
  overflow: scroll;
}
.wrapper {
  left: 0;
  width: 100%;
  height: 100vh;
}
.font_size14_f_5 {
  font-size: 0.37rem;
  color: #666;
}
.flex_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span1 {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 3px;
}
.hui {
  color: #666666;
  font-size: 12px;
}
.red_div {
  color: #f01b60;
  font-weight: bold;
  font-size: 16px;
}
.div {
  padding: 16px;
  border-top: 1px solid #f2f2f2;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 6px;
  margin: 10px;
}
</style>
